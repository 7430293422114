// 分页器
<template>
  <div class="paginations">
    <template v-if="isAll">
      <el-pagination class="page-btns-all" @current-change="handleCurrentChange" :current-page.sync="page"
        :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total" prev-text="上一页" next-text="下一页"
        background>
      </el-pagination>
    </template>
    <template v-else>
      <el-row class="page-btns">
        <el-button class="all-page" type="warning" round disabled>共{{nums}}页</el-button>
        <el-button type="warning" round :disabled="page==1" @click="delPage">上一页</el-button>
        <el-button type="warning" circle>{{page}}</el-button>
        <el-button type="warning" round :disabled="page>=nums" @click="addPage">下一页</el-button>
      </el-row>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Paginations',
  props: {
    isAll: {
      type: Boolean,
      default: false
    },
    nums: { // 总页数
      type: Number,
      default: 1
    },
    total: { // 总条数
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 7
    }
  },
  data () {
    return {
      page: 1,
    };
  },
  watch: {
    page (newVal) {
      this.$emit('changePage', newVal)
    }
  },
  methods: {
    delPage () {
      this.page -= 1
    },
    addPage () {
      this.page += 1
    },
    handleCurrentChange (val) {
      this.page = val
    }
  },
};
</script>

<style scoped lang="less">
.paginations {
  position: relative;
  margin-top: 50px;
}
/deep/.page-btns-all {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  .btn-prev,
  .btn-next {
    background-color: #fbd690;
    padding: 0 10px;
    color: #333333;
  }
  .el-pager {
    .active {
      background-color: #fbd690 !important;
    }
  }
}
.page-btns {
  text-align: right;
  .is-circle {
    width: 40px;
  }
  .all-page {
    color: #fff;
    background-color: #e6a23c;
    border-color: #e6a23c;
  }
}
</style>
