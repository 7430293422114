// 免费书包
<template>
  <div class="partyCulture-freeBags">
    <section class="freeBags-carousel back-border justify-between align-center">
      <div class="carousel-image">
        <el-carousel class="back-border" height="251px" direction="vertical" indicator-position="none" arrow="never"
          :autoplay="false" ref="bagsShow">
          <el-carousel-item v-for="item in data" :key="item">
            <el-image :src="item"></el-image>
          </el-carousel-item>
        </el-carousel>
        <div class="carousel-arrow justify-around">
          <div class="align-center" @click="()=>{this.$refs.bagsShow.prev()}">
            <el-image :src="require('@/assets/images/arrow-top.png')">
            </el-image>
          </div>
          <div class="align-center" @click="()=>{this.$refs.bagsShow.next()}">
            <el-image :src="require('@/assets/images/arrow-bottom.png')">
            </el-image>
          </div>

        </div>
      </div>
      <div class="carousel-info align-center">
        <Info v-if="isData.length" :isData="isData" @setData="setData" />
        <el-image :src="require('@/assets/images/djwh-img1.png')"></el-image>
      </div>
    </section>
    <section v-if="atlas.length" class="freeBags-atlas justify-between">
      <div class="atlas-arrow atlas-left flex-center">
        <i class="el-icon-arrow-left"></i>
      </div>
      <swiper id="mySwiper" class="atlas-list" :options="swiperOption" ref="mySwiper">
        <swiper-slide class="atlas-item " v-for='item of atlas' :key='item'>
          <img class='wh100' :src='item' alt="" />
        </swiper-slide>
      </swiper>
      <div class="atlas-arrow atlas-right flex-center">
        <i class="el-icon-arrow-right"></i>
      </div>
    </section>
    <section class="freeBags-news">
      <el-row class="news-list">
        <el-col class="news-item back-border" :span="24" v-for="item in news" :key="item.id">
          <router-link :to="{path:'/partyCultureDetails',query:{type:'benefit',id:item.id}}">
            <h4 class="news-title">{{item.biaoti}}</h4>
            <div class="news-info justify-between">
              <el-image class="news-img" :src="$base+item.title_url"></el-image>
              <div class="news-text">
                <p class="text-ellipsis-lines">{{item.sy_title}}</p>
                <span>
                  查看详情<i class="el-icon-arrow-right"></i>
                </span>
              </div>
            </div>
          </router-link>
        </el-col>
      </el-row>
      <Paginations v-if="nums" :nums='nums' @changePage="changePage" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getInfo, getNews } from '@/api'
import Info from '@/components/Info'
import Paginations from '@/components/Paginations'
export default {
  components: {
    Info,
    Paginations
  },
  data () {
    return {
      isData: [],
      data: [], // 轮播数据
      atlas: [], // 图集
      news: [], // 新闻数据
      nums: 0, // 总页数
      swiperOption: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 20,
        navigation: {
          prevEl: '.atlas-left',
          nextEl: '.atlas-right',
        }
      }
    };
  },
  computed: {
    ...mapGetters(['getNavItem'])
  },
  mounted () {
    this.getData()
    this.getNews('benefit', 1)
  },
  methods: {
    async getData () {
      try {
        if (this.getNavItem) {
          let { data } = await getInfo(this.getNavItem.id)
          this.isData = data
          let dataArr = JSON.parse(data[0].logo)
          for (let i = 0; i < dataArr.length; i++) {
            this.data.push(this.$base + dataArr[i])
          }
          let atlasArr = JSON.parse(data[0].bg_color)
          for (let i = 0; i < atlasArr.length; i++) {
            this.atlas.push(this.$base + atlasArr[i])
          }
        }
      } catch (error) {

      }
    },
    // 获取新闻
    async getNews (type, page) {
      try {
        let newsRes = await getNews({ type, page })
        this.news = newsRes.data.data
        if (this.news.length) {
          this.nums = Math.ceil(newsRes.data.num / newsRes.data.page_size) // 总页数
        } else {
          this.nums = 0
        }
      } catch (error) {

      }
    },
    setData (data) {
      this.isData = data
    },
    changePage (page) {
      this.getNews('benefit', page)
    }
  },
};
</script>

<style scoped lang="less">
section {
  margin-bottom: 24px;
}
.freeBags-carousel {
  height: 251px;
  padding-right: 30px;

  > div {
    width: 48%;
    height: 100%;
  }
  .carousel-image {
    position: relative;
    .el-carousel {
      .el-image {
        width: 100%;
        height: 100%;
      }
    }
    .carousel-arrow {
      position: absolute;
      top: 50%;
      margin-top: -46px;
      right: -16px;
      z-index: 2;
      width: 34px;
      height: 93px;
      background-color: #fbd690;
      flex-direction: column;
      cursor: pointer;
      > div {
        height: 50%;
      }
    }
  }
  /deep/ .carousel-info {
    position: relative;
    p {
      line-height: 30px;
    }
    .el-image {
      position: absolute;
      right: -30px;
      bottom: 0;
    }
  }
}
.freeBags-atlas {
  .atlas-arrow {
    width: 40px;
    background-color: #e8e8e8;
    border-radius: 4px;
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
      background-color: #999999;
    }
    i {
      color: #fff;
      font-size: 22px;
    }
  }
  .atlas-list {
    flex: 1;
    margin: 0 22px;
    .atlas-item {
    }
  }
}
.freeBags-news {
  .news-item {
    padding: 20px;
    margin-bottom: 20px;
    .news-title {
      font-size: 18px;
      color: #353535;
    }
    .news-info {
      margin-top: 33px;
      .news-img {
        width: 220px;
      }
      .news-text {
        flex: 1;
        margin-left: 33px;
        position: relative;
        p {
          line-height: 25px;
          margin-bottom: 40px;
          -webkit-line-clamp: 5;
        }
        span {
          color: #d9ad22;
          position: absolute;
          right: 0;
          bottom: 0;
          width: auto;
          height: auto;
        }
      }
    }
  }
}
</style>
